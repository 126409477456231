<template>
  <column-layout class="page">
    <!-- Left -->
    <template v-slot:left>
      <Menu />
    </template>

    <!-- Main -->
    <template>
      <div class="widget card pa label-empty">
        <ConnectWallet v-if="!hasConnectedWallet" style="padding: 100px 0px" />

        <template v-else>
          <div class="tab-buttons mb-8">
            <v-btn
              v-for="button in ['Activity', 'Receive', 'Send']"
              :key="button"
              rounded
              class="tab-button"
              :color="tab === button ? 'primary' : 'secondary'"
              @click="tab = button"
            >
              {{ $t("wallet." + button.toLowerCase()) }}
            </v-btn>
          </div>

          <component :is="tabComponent"></component>
        </template>
      </div>
    </template>

    <!-- Right -->
    <template v-slot:right v-if="hasConnectedWallet">
      <BalanceCard />
    </template>
  </column-layout>
</template>

<script>
import BalanceCard from "@/components/app/wallet/BalanceCard.vue";
import Menu from "@/components/app/Menu.vue";

export default {
  metaInfo: { title: "Wallet" },

  data: () => ({
    tab: "Activity",
  }),

  components: {
    ConnectWallet: () => import("@/components/app/wallet/ConnectWallet.vue"),
    ActivityTab: () => import("@/components/app/wallet/ActivityTab.vue"),
    ReceiveTab: () => import("@/components/app/wallet/ReceiveTab.vue"),
    SendTab: () => import("@/components/app/wallet/SendTab.vue"),
    BalanceCard,
    Menu,
  },

  computed: {
    tabComponent() {
      return this.tab + "Tab";
    },

    hasConnectedWallet() {
      return !!this.$user.public_address;
    },
  },
};
</script>

<style lang="scss"></style>
