<template>
  <div class="balance-card widget card pa label-empty">
    <!-- Buttons -->
    <div class="tab-buttons mb-5">
      <v-btn color="primary" rounded class="tab-button">
        {{ $t("wallet.tokens") }}
      </v-btn>

      <v-btn color="secondary" rounded class="tab-button">
        {{ $t("wallet.nfts") }}
      </v-btn>
    </div>

    <!-- Card -->
    <v-img
      :src="require('@/assets/images/cfoot-bg.png')"
      alt="C-Foot background"
      class="d-flex pa-5 rounded-lg"
      max-width="300"
    >
      <v-img
        :src="require('@/assets/images/cfoot-square.png')"
        alt="C-Foot logo"
        width="41"
      />

      <div
        class="pos-abs rounded-lg px-5 font-weight-medium py-1"
        style="top: 20px; right: 20px; background-color: rgba(1, 1, 1, 0.4)"
      >
        USD 168,25
      </div>

      <div class="pos-abs font-weight-bold" style="bottom: 10px">
        <div>{{ $t("wallet.balance") }}</div>

        <div v-if="balance !== null" style="font-size: 30px">
          <span>{{ balance }}</span>
          <span class="ml-4">C-Foot</span>
        </div>
      </div>
    </v-img>
  </div>
</template>

<script>
export default {
  data: () => ({
    balance: null,
  }),

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store
        .dispatch("wallet/balance")
        .then(({ balance }) => (this.balance = balance))
        .catch(() => this.$toast.error("Error while fetching wallet balance."));
    },
  },
};
</script>

<style></style>
